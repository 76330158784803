// Facebook MetaPixel Code
import React, { useEffect } from "react";

const MetaPixel = () => {
    useEffect(() => {
        if (typeof window.fbq === 'undefined') {
            !function (f, b, e, v, n, t, s) {
                if (f.fbq) return; n = f.fbq = function () {
                    n.callMethod ?
                        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                };
                if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                n.queue = []; t = b.createElement(e); t.async = !0;
                t.src = v; s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s)
            }(window, document, 'script',
                'https://connect.facebook.net/en_US/fbevents.js');
            window.fbq('init', '1016898390211809');
            window.fbq('track', 'PageView');
            // console.log('fbq if : ', window.fbq);
        } else {
            window.fbq('track', 'PageView');
            // console.log('fbq else : ', window.fbq);
        }
    }, []);

    return null;
};

export default MetaPixel;