import "./App.css";
import Routers from "./Route/index";
import AuthProvider from "./contexts/AuthContext";
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  ReactGA.initialize('G-C07PCNNPN6');
  return (
    <HelmetProvider>
      <div className="App">
        <AuthProvider>
          <Routers />
        </AuthProvider>
      </div>
    </HelmetProvider>
  );
}

export default App;
