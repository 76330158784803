import presale_text_home from "assets/images/main/common/presale_text_home.svg";
import join_our_community from "assets/images/main/common/join_our_community.svg";
import { BUY_NOW_URL } from "utils/config/app.config";
const Presale = () => {
    return (
        <div className="z-20 flex items-center justify-center w-full">
            <div className="bg-center bg-cover bg-no-repeat max-w-[1440px] px-6 md:px-[64px] lg:px-[120px]">
                {/* <h1 className="font-[inter] font-medium lg:font-bold text-[48px] lg:text-[80px] lg:leading-[120px] tracking-[-0.02em] text-center max-w-[1200px]">
                    PRESALE
                </h1> */}
                <div className="flex justify-center w-full">
                    <img src={presale_text_home} alt="" />
                </div>
                <p className="text-center font-[inter] font-medium lg:font-bold text-[52px] lg:text-[72px] lg:leading-[60px]">
                    IS NOW LIVE
                </p><br></br><br></br>
                <p className="flex justify-center w-full">
                    <a href={BUY_NOW_URL} target="_blank" className="inline-block py-2 px-8 bg-white text-[#000000] text-[32px] font-medium rounded-[32px] w-fit cursor-pointer">
                        Buy Now
                    </a>
                </p>
                <p className="text-center font-[HelveticaNeueLight] text-white font-normal md:text-[42px] leading-[80px] mt-8">
                    Introducing $OZ Token
                </p>
                <p className="text-center text-white font-[HelveticaNeueLight] font-normal md:text-[38px] leading-[80px] mt-8">
                    <nobr>OZ is the fuel that empowers the Ozak Al ecosystem</nobr>
                </p>
                <p className="text-center text-color-semilight text-[18px] leading-[25px] font-[archivo] font-normal">
                    Stay tuned to all social channels of Ozak to grab $OZ early on. <br />
                    Early birds wins great rewards & chance to buy $OZ with 1000x upside potential.
                </p>
                <p className="text-center text-color-semilight text-[18px] leading-[25px] font-[archivo] font-normal">
                    &nbsp;
                </p>
                <div className="flex justify-center w-full">
                    <a href="https://t.me/OzakAGI" target="_blank">
                        <img src={join_our_community} alt="" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Presale;