import cn from "classnames";
import { useMediaQuery } from "react-responsive";
import tokenomicsGraphImg from "assets/images/main/common/tokenomics-graph.svg";
import Whitepaper from "assets/docs/Ozak AI whitepaper V 1.0.pdf";
import LearnMore from "assets/docs/Ozak AI Economic Paper V 2.0.pdf";


const Tokenomics = () => {
    const isTablet = useMediaQuery({ maxWidth: 960 });
    return (
        <div className="bg-[#0d0d0d] py-32 px-6 md:px-10 lg:px-28">
            <div className="flex flex-row gap-6 xs:flex-col-reverse md:flex-col-reverse lg:flex-row">
                <div className="basis-2/3">
                    <p className="font-normal text-[16px] font-[archivo] tracking-wide text-color-semilight">
                        TOKENOMICS
                    </p>
                    <div className="font-[archivo] text-[48px] leading-[65px]">
                        Transparent & Fair Token
                        Distribution<br />
                        Ticker: $OZ
                    </div>
                    <p className="text-[27px] font-bold font-[archivo] mb-6 mt-2">
                        <span className="text-[#59EF95] mr-4">70%</span>
                        <span className="text-white mr-4">$OZ</span>
                        <span className="text-color-semilight text-[20px] font-normal">
                            in Presale & Community
                        </span>
                    </p>
                    <p className="text-color-semilight text-[18px] leading-[29px] font-[archivo] font-normal">
                        OZAK AI ensures a balanced, transparent & fair token allocation
                        & distribution to support the growth & sustainability of our
                        ecosystem. Here's how the tokens are distributed:
                    </p>
                </div>
                <div className="flex justify-end sm:justify-right sm:mb-20">
                    <img src={tokenomicsGraphImg} alt="" />
                </div>
            </div>

            <hr className="mt-12 mb-6 border-[#474646]" />
            <div className={cn("flex gap-4", { "flex-col": isTablet })}>
                <div>
                    <p className="text-color-semilight text-[18px] leading-[29px] font-[archivo] font-normal">
                        $OZ has a Total Supply of 10 Billion Tokens. No more than 10 Billion $OZ can be
                        minted in lifetime. The supply tends to be deflationery as part of the economic plan.
                    </p>
                </div>
                <div className="flex gap-2 items-center justify-end">
                    <a className="btn-2" href={LearnMore} target="_blank">
                        Learn More
                    </a>
                    <a className="btn-2" href={Whitepaper} target="_blank">
                        Whitepaper
                    </a>
                    {/* <a className="btn-2" href="#" target="_blank">
                        Audit
                    </a> */}
                </div>
            </div>
        </div>
    )
}

export default Tokenomics;